import * as React from "react";
import {Box, Grid, Typography} from "@mui/material";
import playstore from "../logo/google-play-badge.png";
import appstore from "../logo/apple.svg";
import Link from "@mui/material/Link";
import image_intro from "../images/various-merchants2.png";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import "../style/Introduction.css";
import back1 from "../images/back1.png"

function Introduction() {
    return (
        <Grid
            id="accueil"
            sx={{
                paddingTop: "1%",
                paddingLeft: {xs: "2.5%", sm: "5%"},
                paddingRight: {xs: "2.5%", sm: "5%"},
                paddingBottom: "3%",
                maxWidth: "xl",
                margin: "auto",
                backgroundImage: `url(${back1})`,
                backgroundSize: "cover",
                // backgroundPosition: "center",
            }}
        >
            <Grid
                container
                justifyContent="center"
                alignItems="top"
                spacing={3}
                sx={{paddingTop: {xs: "40%", sm: "20%", md: "8%", xl: "7%"}}}
            >

                <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: "PinkSunset-Regular",
                            fontStyle: {color: "#01796F"},
                            maxWidth: {xs: "100%", sm:"90%", md: "90%"},
                            paddingBottom: {xs: "0%", md: "3.5%", xl: "2%"},
                            paddingTop: {xs: "0%", sm:"7%", md: "14%", xl: "10%"},
                            textAlign: {xs: "center", md: "center"},
                            fontWeight: "regular"
                        }}
                    >
                        Transforme ta façon de consommer avec MCP
                    </Typography>
                    <Grid sx={{marginLeft: "10px", textAlign: {xs: "center", md: "left"}}}>
                        <Grid alignItems={'center'} justifyContent={"center"} textAlign="left">
                            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, marginTop: 5}}>
                                ✔️ <b style={{color: "#3fb498"}}>Gagne du cash</b> et profite d’offres exclusives
                            </Typography>
                            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                                ✔️ Anticipe tes achats et <b style={{color: "#e9d6a8"}}>trouve</b> tes produits <b style={{color: "#206657"}}>facilement</b>
                            </Typography>
                            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                                ✔️ <b style={{color: "#2d8376"}}>Soutiens</b> les commerces locaux
                            </Typography>
                            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                                ✔️ Bénéficie d'une <b style={{color: "#3fb498"}}>expérience</b> de shopping <b style={{color: "#e9d6a8"}}>personnalisée</b>
                            </Typography>


                            <Grid container alignItems={'center'}
                                  sx={{
                                      marginLeft: "auto",
                                      justifyContent: {xs: "center", md: "center"},
                                      marginTop: {xs: "5%", md: "13%"}
                                  }}>
                                <Link
                                    href="https://www.apple.com/app-store/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        display: "inline-block",
                                        overflow: "hidden",
                                        borderRadius: "13px",
                                        margin: 1
                                    }}
                                >
                                    <img
                                        src={appstore}
                                        alt="Télécharger sur l'App Store"
                                        height={50}
                                    />
                                </Link>
                                <Link
                                    href="https://play.google.com/store"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        display: "inline-block",
                                        overflow: "hidden",
                                        borderRadius: "13px",
                                        margin: 1
                                    }}
                                >
                                    <img
                                        src={playstore}
                                        alt="Télécharer sur Google Play"
                                        height={60}
                                    />
                                </Link>
                            </Grid>
                            <Grid container marginTop={"5%"} alignItems={'center'}
                                  sx={{marginLeft: "auto", justifyContent: {xs: "center", md: "center"}}}>
                                <Typography sx={{
                                    textAlign: "center", padding: "2.5%", borderRadius: 2, backgroundColor: "#01796F",
                                    fontFamily: "Outfit", fontWeight: "bold", color: "white"
                                }}>Bientôt disponible !</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} justifyContent="center" alignContent={"center"}>
                    <Box sx={{
                        filter: "drop-shadow(5px 5px 6px #010101)",
                        display: "absolute",
                        justifyContent: "center",
                        maxWidth: {xs: "450px", sm: "520px", md: "550px", xl: "550px"},
                        paddingTop: {xs: 6}
                    }}>
                        <img src={image_intro} width={"95%"}
                                 alt="Commerçants divers"/>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: {xs: "10%", sm: "3%"}
            }}>
            <Link href="/#map" width={"wrap-content"}>
                <KeyboardDoubleArrowDownIcon sx={{
                    color: "rgba(63, 135, 123, 1)",
                    fontSize: 40,
                }}></KeyboardDoubleArrowDownIcon>
            </Link>
            </Box>
        </Grid>
    );
}

export default Introduction;
