import "../style/subnews.css"

export default function UnsubNewsletter() {
    return (
        <div className="subnews">
            <iframe height={600} width={800} title={"Form to unsubscribe"}
                    src="https://1e0d7774.sibforms.com/serve/MUIFAO1MDh-JDQbwryKwBkmY5alc-3rDKPFlR81ZUn2S1XC2VillzUlzEri5I_cOqKhJTvHZLd7LaLY4ZnFxxJJSvVEwKAfwl-XTYdXZJqKgIA5fT2ylYjXmLhoaSlqeO-goWKy-gT7wkCYtIP_i5SR-w55LYrHxMeEzHx_sejAHgvtD_a_n9qXlgMcSnwDiPgvwu4kKrc2Wj9Pj"
                    allowFullScreen
                    style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%"}}></iframe>
        </div>
    )
}