// routes.js
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import ConditionGenerales from './components/cgu';
import MentionsLegales from './components/MentionsLegales';
import PolitiqueConf from "./components/PolitiqueConf";
import Navigation from "./components/mobilenav";
import SubNewsletter from "./components/SubNewsletter";
import UnsubNewsletter from "./components/UnsubNewsletter";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/accueil" element={<Home/>}/>
            <Route path="/" element={<Home/>}/>
            <Route path="/conditions-générales-utilisation" element={<ConditionGenerales/>}/>
            <Route path="/mentions-légales" element={<MentionsLegales/>}/>
            <Route path="/confidentialité" element={<PolitiqueConf/>}/>
            <Route path="/test" element={<Navigation/>}/>
            <Route path="/subscribe" element={<SubNewsletter/>}/>
            <Route path="/unsubscribe" element={<UnsubNewsletter/>}/>
        </Routes>
    );
};

export default AppRoutes;