import * as React from "react";
import Box from "@mui/material/Box";
import local from '../images/local.jpg'
import {Grid, Typography} from "@mui/material";

export default function Soutenir() {
    return (
        <Box
            component="section"
            sx={{
                padding: "1%", p: 2, height: "auto",
                backgroundColor: "#01796F",
                // backgroundColor: "#01796F",
                maxWidth: "xl",
                margin: "auto"
            }}
            id="concept"
        >
            <Grid container sx={{width: "90%", margin: "auto"}}>
                <Grid item sx={{marginTop: "80px"}}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: "#FFFFFF",
                            fontFamily: "PinkSunset-Regular, sans-serif",
                            textAlign: "center",
                            fontWeight: "regular",
                            marginBottom: "50px"
                        }}
                    >
                        Soutiens le commerce local autour de toi
                    </Typography>
                    <Grid item>
                        <Typography
                            variant="h6"
                            sx={{textAlign: "center", fontWeight: "bold"}}
                        >
                            <img
                                src={local}
                                width={"70%"}
                                style={{borderRadius: 20, filter: "drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5))"}}
                                alt="Logo"/>
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: "#FFFFFF",
                            fontFamily: "Outfit, sans-serif",
                            paddingTop: "5%",
                            textAlign: "center",
                            width: "90%",
                            margin: "auto"
                        }}>
                            Avec MCP, soutenir les commerces locaux est plus qu'une simple action, c'est un véritable
                            engagement envers ta communauté locale.
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: "#FFFFFF",
                            fontFamily: "Outfit, sans-serif",
                            paddingTop: "2%",
                            textAlign: "center",
                            width: "90%",
                            margin: "auto"
                        }}>
                            Explore une multitude de boutiques uniques et de producteurs locaux directement depuis ton
                            téléphone, et découvre une variété de produits artisanaux et authentiques à chaque coin de
                            rue.
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: "#FFFFFF",
                            fontFamily: "Outfit, sans-serif",
                            paddingTop: "2%",
                            textAlign: "center",
                            width: "90%",
                            margin: "auto"
                        }}>
                            Chaque réservation que tu fais via notre plateforme est un pas de plus vers la préservation
                            de l'économie locale, offrant un soutien indispensable aux entrepreneurs passionnés qui font
                            vivre ton quartier. En retour, découvre leurs promotions en un clin d'oeil,
                            tente de gagner de l’argent pour tes achats responsables et contribue à bâtir un avenir durable
                            pour tous.
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: "#FFFFFF",
                            fontFamily: "Outfit, sans-serif",
                            paddingTop: "2%",
                            paddingBottom: "7%",
                            textAlign: "center",
                            width: "90%",
                            margin: "auto"
                        }}>
                            Inscris-toi à notre newsletter dés maintenant pour suivre de près la sortie de MCP, et
                            deviens un véritable héros de ton quartier !
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
