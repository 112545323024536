import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Box, Grid} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import GmailIcon from "@mui/icons-material/Mail";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../logo/logo_full.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AppleLogo from "../logo/apple.svg";
import AndroidLogo from "../logo/google-play-badge.png";

export default function Footer() {
    return (
        <Box className="subnews"
            sx={{
                backgroundColor: "#FFFFFF", //"#FCFAF5",
                mt: 5,
                p: 2,
                //width: "90%",
                margin: "auto",
                justifyContent: "center",
                maxWidth: "xl"
            }}
            component="Footer"
        >
            <Grid
                sx={{
                    paddingTop: "3%",
                    display: "flex",
                    alignItems: {xs: "center"},
                    justifyContent: {xs: "center", md: "center", sm: "center"},
                    flexDirection: {xs: "column", md: "row", sm: "row"},
                    flexWrap: "wrap"
                }}
            >
                <Grid item sx={{paddingBottom: {xs: "3%", sm:"0%", md: "0%", xl: "0%"}}}>
                    <img src={logo} alt="MCP Logo" width={"175px"}/>
                    <Grid sx={{display: "flex", alignItems: "center"}}>
                        <Link
                            href="https://www.apple.com/app-store/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{margin: 2}}
                        >
                            <img
                                src={AppleLogo}
                                alt="Télécharger dans l'App Store"
                                width={100}
                            />
                        </Link>
                        <Link
                            href="https://play.google.com/store"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{margin: 2}}
                        >
                            <img
                                src={AndroidLogo}
                                alt="Disponible sur Google Play"
                                width={100}
                            />
                        </Link>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        flexGrow: 1,
                        justifyContent: "center",
                        justifyItems: "space-around",
                    }}
                    xs={6}
                >
                    <Grid item>
                        <Box>
                            <Box
                                sx={{
                                    paddingLeft: 2,
                                    textAlign: "center",
                                    paddingTop: {xs: 1, md: 0},
                                    paddingBottom: {xs: 1, md: 0}
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: "PinkSunset-Regular, sans-serif",
                                        color: "#3F877B",
                                    }}
                                >
                                    Newsletter
                                </Typography>
                                    <Typography><Link
                                        variant="body1"
                                        sx={{
                                            fontFamily: "Outfit, sans-serif",
                                            color: "#3F877B",
                                            textAlign: "center",
                                        }}
                                        color="text.primary"
                                        href="/subscribe"
                                    >
                                        S'inscrire
                                    </Link>
                                    </Typography>
                                <Typography>
                                    <Link
                                    variant="body1"
                                    sx={{
                                        fontFamily: "Outfit, sans-serif",
                                        color: "#3F877B",
                                        textAlign: "center",
                                    }}
                                    color="text.primary"
                                    href="/unsubscribe"
                                >
                                    Se désinscrire
                                </Link></Typography>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
                {/* ********************************** */}
                <Grid
                    sx={{
                        textAlign: {xs: "center", md: "right"},
                        justifyContent: {xs: "center"},
                        mt: {xs: 1, md: 0},
                    }}
                >
                    <Grid item sx={{paddingTop: {xs: "5%", sm:"2%", md: "0%", xl: "0%"}}}>
                        <Link
                            href="mailto:contact@moncommercantdeproximite.fr"
                            color="#3FB498"
                            sx={{margin: "8px"}}
                        >
                            <GmailIcon sx={{fontSize: "40px"}}/>
                        </Link>
                        <Link
                            href="https://www.linkedin.com/company/moncommercantdeproximite/"
                            color="#2D8376"
                            sx={{margin: "8px"}}
                        >
                            <LinkedInIcon sx={{fontSize: "40px"}}/>
                        </Link>
                        <Link
                            href="https://www.instagram.com/mcp.app"
                            color="#3FB498"
                            sx={{margin: "8px"}}
                        >
                            <InstagramIcon sx={{fontSize: "40px"}}/>
                        </Link>
                        <Link
                            href="https://www.facebook.com/profile.php?id=61554491464977"
                            color="#2D8376"
                            sx={{margin: "8px"}}
                        >
                            <FacebookIcon sx={{fontSize: "40px"}}/>
                        </Link>
                    </Grid>

                    <Grid
                        item
                        sx={{
                            mr: {xs: 2, md: 2},
                            textAlign: "center",
                            margin: "8px",
                        }}
                    >
                        <Typography>
                            <Link
                                href="mailto:contact@moncommercantdeproximite.fr"
                                color="#3FB498"
                            >
                                contact@moncommercantdeproximite.fr
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                sx={{
                    paddingLeft: "2.5%",
                    paddingRight: "2.5%",
                    display: "flex",
                    justifyContent: {xs: "center", md: "space-between"},
                    flexDirection: {md: "row"},
                    mt: 1,
                }}
            >
                <Grid item sx={{paddingTop: {xs: "1%", sm:"0%", md: "0%", xl: "0%"}, paddingBottom: {xs: "2%", sm:"0%", md: "0%", xl: "0%"}}}>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{
                            mr: {xs: 1, md: 1},
                        }}
                    >
                        © 2024 MCP - Mon commerçant de proximité
                    </Typography>
                </Grid>

                <Grid item sx={{textAlign: {xs: "center", md: "right"}}}>
                    <Link
                        color="text.primary"
                        href="/conditions-générales-utilisation"
                        sx={{
                            display: {xs: "block", md: "inline"},
                            mr: {xs: 0, md: 1},
                        }}
                    >
                        Conditions générales d'utilisation
                    </Link>
                    <Link
                        color="text.primary"
                        href="/confidentialité"
                        sx={{
                            display: {xs: "block", md: "inline"},
                            mr: {xs: 0, md: 1},
                        }}
                    >
                        Politique de confidentialité
                    </Link>
                    <Link color="text.primary" href="/mentions-légales">
                        Mentions légales
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
}
