import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const PolitiqueConf = () => {
    return (
        <Box className="subnews" sx={{
            maxWidth: "xl",
            margin: "auto"
        }}>
            <Typography variant="h4"
                        sx={{fontFamily: "Outfit, sans-serif", margin: 2, textAlign: "center", paddingBottom: 8}}>
                POLITIQUE DE CONFIDENTIALITÉ
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, paddingBottom: 8, textAlign: "center"}}>
                La présente politique de confidentialité indique les conditions dans lesquelles MCP - Mon commerçant de
                proximité traite vos données personnelles et vous informe de vos droits concernant vos données. MCP -
                Mon commerçant de proximité (ci-après “l’Application”) est éditée par MCP - Mon commerçant de proximité,
                société par actions simplifiée au capital de 2 000 € dont le siège social est situé 67 allée des
                tourterelles 13500 Martigues, immatriculée au RCS de Aix-en-Provence 925 383 507 et représentée par
                Monsieur Nans KARAYANNIDIS, CEO. Les données sont hébergées par MCP - Mon commerçant de proximité pour
                la partie applicative mobile et par AWS région Londres pour la partie site web.
            </Typography>
            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                1. Nature des données personnelles collectées
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Nous sommes susceptibles de collecter et traiter les données personnelles suivantes vous concernant :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}> ● Identité et coordonnées : nom, prénom,
                    adresse email, numéros de téléphone, photo de profil, coordonnées de géolocalisation, date de
                    naissance, pour les professionnels, nous collectons également les données suivantes : nom du
                    commerce, type de commerce, raison sociale, numéro SIREN, date d’immatriculation, e-mail générique
                    de contact, adresse du commerce, adresse du siège social, numéro de téléphone du commerce et secteur
                    d’activité, bénéficiaire effectif.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Identifiants utilisateur.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Contenus : textes, images, documents,
                    fichiers audio, vidéos, notes et avis.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Informations relatives à l’utilisation
                    de l’Application : les informations sur votre activité, vos
                    recherches de villes ou de commerces, sur la manière dont vous interagissez avec les autres
                    utilisateurs ainsi que l'heure, la fréquence et la durée de vos activités, les informations sur les
                    fonctionnalités que vous utilisez, si vous êtes en ligne ou non, l'horodatage lié à votre
                    utilisation, notamment la dernière fois que vous avez utilisé l’Application ainsi que l'heure et
                    le jour où vous avez envoyé et reçu des messages.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Autres données d'identification : le
                    modèle du matériel utilisé pour accéder à l’Application et
                    au site web, les informations sur le système d'exploitation, la version de l'application, les
                    informations du navigateur, le réseau mobile, les données de connexion (y compris si vous
                    utilisez le Wi-Fi ou les données cellulaires), l'opérateur téléphonique ou le fournisseur d'accès
                    à Internet (FAI), la langue et le fuseau horaire, l'adresse IP, les informations concernant les
                    activités sur l'appareil et les identifiants notamment ceux fournis par nos
                    prestataires.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Données financières et
                    transactionnelles : IBAN, trois derniers numéros de carte, date d'expiration de votre carte bleue,
                    informations relatives aux transactions réalisées (nombre, montant, date, heure, lieu), montant du
                    solde de cash in-app disponible.</Typography>
            </Typography>
            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                2. Mode de collecte des données personnelles
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, paddingBottom: 5}}>
                Nous collectons les données vous concernant des manières suivantes :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Directement auprès de vous sur
                    l'Application et/ou le site web :
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>○ Lors de votre inscription sur
                        l’Application;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>○ Si vous soumettez un formulaire sur
                        le Site Web ou l'Application;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>○ Si vous nous contactez pour une
                        quelconque raison.</Typography></Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Automatiquement si vous interagissez
                    avec le Site Web ou l'Application en utilisant des
                    cookies, tags et autres technologies similaires de collecte automatique des données.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Auprès de prestataires nous permettant
                    d’analyser et d’améliorer votre expérience utilisateur.</Typography>
            </Typography>
            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                3. Utilisation des données personnelles
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, paddingBottom: 5}}>
                Nous utilisons vos données personnelles dans les circonstances suivantes :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Lorsque que cela est nécessaire pour
                    exécuter le contrat conclu avec nous afin d’utiliser l’Application et notamment les Conditions
                    Générales d’Utilisation ou les Conditions Générales de Service et pour les finalités suivantes:
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Assurer la création et la gestion
                        de votre compte particulier ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Assurer la création et la gestion
                        de votre compte professionnel ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Assurer la création et la gestion
                        de vos profils et annonces ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous notifier de tout changement
                        apporté à l’Application ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous permettre de réaliser des
                        transactions ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous permettre de recevoir des
                        offres commerciales des commerçants auprès de qui vous avez déjà réalisé des achats ou auxquels
                        vous vous êtes abonnés ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Mettre en œuvre un support
                        utilisateurs à destinations des Professionnels et Particuliers.</Typography></Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Lorsque cela est dans l’intérêt
                    légitime de MCP - Mon commerçant de proximité et pour les finalités suivantes :
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Analyser et améliorer votre
                        expérience utilisateur ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Réaliser des enquêtes de
                        satisfaction ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous contacter concernant la
                        création de votre compte et l’utilisation de nos
                        services ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous exclure de nos campagnes de
                        marketing digital à destination des non-utilisateurs de l’application ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous transmettre en tant que
                        particulier des informations sur l’actualité de MCP - Mon commerçant de proximité;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous envoyer des communications sur
                        les professionnels inscrits sur l’Application à des fins promotionnelles et d’information
                        ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Détecter et prévenir toute activité
                        potentiellement frauduleuse ou autre activité en violation avec nos conditions générales
                        ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Définir votre localisation pour
                        vous fournir des prestations de service adaptées ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Mettre en œuvre une procédure de
                        gestion des litiges avec les utilisateurs.</Typography></Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Lorsque nous devons satisfaire nos
                    obligations légales ou règlementaires et notamment pour
                    les finalités suivantes :
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Lutter contre la fraude, le
                        blanchiment d’argent et le financement du terrorisme ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Se constituer des
                        preuves.</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Lorsque que vous y consentez
                        explicitement et pour les finalités suivantes, nous utilisons vos
                        données pour :</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Vous permettre de localiser les
                        professionnels et des événements proches de vous et
                        connaître l’endroit à partir duquel vous avez utilisé l’Application ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Nous permettre d’analyser votre
                        navigation sur notre site et d'échanger avec nos
                        équipes ;</Typography>
                    <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>❖ Accéder à votre appareil photo
                        et/ou à votre galerie de photos pour mettre à jour la photo de votre profil ou de vos
                        produits.</Typography></Typography>
            </Typography>
            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                4. Partage des données personnelles
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les données que nous collectons sont accessibles à nos prestataires de services agissant en qualité de
                sous-traitants, qui participent techniquement à la réalisation des finalités poursuivies et notamment
                avec :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>- AWS région Londres pour l’hébergement
                    des données du site web ;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>- TomTom pour la géolocalisation et la
                    création de cartes en ligne ;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>- Elasticsearch pour la gestion des
                    données de journalisation ;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>- Firebase et Firebase Analytics pour la
                    gestion des push notifications ;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>- Brevo pour l’envoi de sms et
                    d’e-mails.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Dans ce cadre, les données que nous
                    collectons peuvent être transférées hors de l’Union Européenne.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Dans ce cas, nous nous assurons que ce
                    transfert s’effectue à destination des pays reconnus comme
                    assurant un niveau adéquat de protection de vos données personnelles ou, à tout le moins, sur la
                    base
                    des garanties appropriées prévues par la loi.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Afin de respecter nos obligations
                    légales, vos données personnelles peuvent également être transmises
                    aux autorités administratives et judiciaires autorisées, uniquement sur réquisition
                    judiciaire.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Certains prestataires, tels que les
                    services de géolocalisation TomTom, auxquels nous faisons appel
                    sont susceptibles de collecter, avec votre consentement, des données en qualité de responsable de
                    traitement.</Typography>
            </Typography>
            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                5. Durées de conservation des données personnelles
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP - Mon commerçant de proximité conserve vos données personnelles aussi longtemps que nécessaire au
                regard des finalités énoncées dans la présente Politique et pendant toute période supplémentaire
                susceptible d'être requise ou autorisée par la loi. Les durées de conservation des données sont
                déterminées en prenant en considération les éléments suivants :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● La durée de notre relation (à savoir la
                    durée d’utilisation de l’application, la désactivation de
                    votre compte, votre besoin d'être reconnu lorsque vous nous contactez);</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Les exigences légales de conservation
                    des données;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● Les délais de
                    prescription.</Typography>
            </Typography>
            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                6. Droit des personnes concernées et exercice de ces droits
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                En application de la réglementation applicable aux données à caractère personnel, vous disposez des
                droits suivants :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● droit d’accès : vous pouvez exercer ce
                    droit pour connaître les données personnelles vous
                    concernant, en écrivant à l'adresse électronique mentionnée ci-dessous. Dans ce cas, avant la
                    mise en œuvre de ce droit, MCP - Mon commerçant de proximité peut vous demander une preuve de votre
                    identité afin d'en vérifier l'exactitude;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● droit de rectification : si les données
                    à caractère personnel détenues par MCP - Mon commerçant de proximité sont inexactes, vous pouvez
                    demander leur modification;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● droit de suppression des données : vous
                    pouvez demander la suppression ou l'effacement de
                    vos données personnelles s'il n'y a pas de raison valable pour MCP - Mon commerçant de proximité de
                    continuer à les traiter.
                    Vous avez aussi le droit de demander la suppression de vos données personnelles si vous avez
                    exercé votre droit d'opposition au traitement de vos données personnelles avec succès, si vos
                    données personnelles sont traitées de manière illégale ou lorsque MCP - Mon commerçant de proximité
                    doit effacer vos données personnelles afin d'être en conformité avec la loi locale. Toutefois, il
                    est possible que MCP - Mon commerçant de proximité ne puisse pas satisfaire votre demande pour des
                    raisons légales spécifiques qui vous seront communiquées.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● droit à la limitation du traitement :
                    vous pouvez demander à MCP - Mon commerçant de proximité de limiter le traitement de vos données
                    personnelles;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● droit de s’opposer au traitement des
                    données : vous pouvez vous opposer à ce que vos données soient traitées;</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>● droit à la portabilité : vous pouvez
                    réclamer que MCP - Mon commerçant de proximité vous remette les données personnelles vous concernant
                    pour les transmettre à un tiers.</Typography>
            </Typography>
            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                7. Contact et réclamations
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Vous pouvez exercer vos droits en nous contactant à l’adresse suivante :

                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>MCP - Mon commerçant de
                    proximité</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>67 allée des tourterelles</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>13500 Martigues</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Ou par e-mail, à l’adresse :
                    privacy@moncommercantdeproximite.fr</Typography>

                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Toute demande doit être accompagnée de la
                    photocopie d’un titre d’identité en cours de validité signé
                    et faire mention de l’adresse à laquelle MCP - Mon commerçant de proximité pourra contacter le
                    demandeur. La réponse sera adressée dans le mois suivant la réception de la demande. Ce délai d'un
                    mois peut être prolongé de deux mois si la complexité de la demande et/ou le nombre de demandes
                    l'exigent.</Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Les personnes qui le souhaitent, ont la
                    possibilité d’organiser le sort de leurs données après leur décès.</Typography>

                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Pour plus d'informations sur le sujet,
                    vous pouvez consulter le site Internet de la CNIL.</Typography>

                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>Les utilisateurs peuvent aussi introduire
                    une réclamation auprès de la CNIL.</Typography>
            </Typography>
        </Box>
    );
};

export default PolitiqueConf;
