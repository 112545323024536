import * as React from "react";
import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import ecologique from "../logo/Ecologique.png";
import economique from "../logo/Economique.png";
import precision from "../logo/Précision.png";
import visibilite from "../logo/Visibilité.png";
import simplicite from "../logo/Simplicité.png";
import proximite from "../logo/Proximité.png";

export default function NotreADN() {
    return (
        <Box
            component="section"
            sx={{
                p: 2, height: "auto", backgroundColor: "#FFFFFF",
                maxWidth: "xl",
                margin: "auto",
                position: "relative", // Activer le positionnement absolu pour les pseudo-éléments
                overflow: "hidden", // Empêcher les débordements
                "::before, ::after": {
                    content: '""',
                    display:{xs: "none", md: "block", sm: "block"}, // cacher les demi-cercles pour les petits ecrans  
                    position: "absolute",
                    width: "200PX", // Ajuster la taille selon les besoins
                    height: "200px", // Ajuster la taille selon les besoins
                    backgroundColor: "white", // Couleur des demi-cercles
                    borderRadius: "50%",
                    zIndex: 0,
                    border : "8px solid #01796F"
                },
                "::before": {
                    bottom: "60px", // Ajuster la position vers le haut
                    left: "-15px", // Ajuster la position vers l'extérieur
                    transform: "translate(-50%, 30%)"
                },
                "::after": {
                    top: "100px", // Ajuster la position vers le bas
                    right: "10px", // Ajuster la position vers l'extérieur
                    transform: "translate(50%, -30%)"
                }
            }}
            id="valeurs"
        >
            <Grid item sx={{marginTop: '80px'}}>
                <Typography
                    variant="h4"
                    sx={{fontFamily: "PinkSunset-Regular, sans-serif", textAlign: "center", fontWeight: "regular", color:"#01796F"}}
                >
                    Notre ADN
                </Typography>
            </Grid>
            <Grid
                container
                spacing={2}
                sx={{
                    alignItems: "center",
                    marginTop: 3,
                    marginBottom: {xs: "30px", sm: '50px'},
                    justifyContent: {xs: '', sm: "space-around"},
                    width: {xs: "75%", md: "75%", sm: "75%"},
                    margin: "auto"
                }}
            >
                {/* Premier groupe de grilles */}
                <Grid container spacing={2} sx={{marginTop: 3, justifyContent: "space-around"}}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={precision} width={"60px"} alt="logo picto MCP"/>
                            <br/>
                            Précision
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Grâce à des fonctionnalités en temps réel.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={visibilite} width={"60px"} alt="logo picto MCP"/>
                            <br/>
                            Visibilité
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Offerte aux commerçants à travers notre solution.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={proximite} width={"60px"} alt="logo picto MCP"/>
                            <br/>
                            Proximité
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Promouvoir les savoirs-faire et les produits locaux.
                        </Typography>
                    </Grid>
                </Grid>

                {/* Deuxième groupe de grilles */}
                <Grid container spacing={2} sx={{marginTop: 3, justifyContent: "space-around"}}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={economique} width={"60px"} alt="logo picto MCP"/>
                            <br/>
                            Économique
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Consommer local c'est participer au développement des commerces français.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={ecologique} width={"60px"} alt="logo picto MCP"/>
                            <br/>
                            Écologique
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Les circuits courts favorisent une empreinte carbone faible.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={simplicite} width={"60px"} alt="logo picto MCP"/>
                            <br/>
                            Simplicité
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Abstraction de la phase de découverte des commerces autour de soi.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );
}
