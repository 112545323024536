
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
const MentionsLegales = () => {
  return (
    <Box className="subnews"
         sx={{
             maxWidth: "xl",
             margin: "auto"
         }}>
      <Typography variant="h4" sx={{fontFamily: "Outfit, sans-serif", margin: 2, textAlign:"center", paddingBottom: 8}}>
          MENTIONS LÉGALES
      </Typography>
      <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, paddingBottom: 8, textAlign:"center"}}>
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site MCP - Mon commerçant de proximité l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
      </Typography>
        <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          Edition du site
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
            {"Le présent site, accessible à l’URL   "}
            <a href="https://moncommercantdeproximite.fr/" rel="noreferrer noopener">
            https://moncommercantdeproximite.fr
        </a>
            {"  (le « Site »), est édité par :"}
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          MCP - Mon commerçant de proximité , SAS au capital de 2 000 euros, inscrite au R.C.S. de AIX-EN-PROVENCE sous le numéro 925 383 507, dont le siège social est situé au 67 allée des tourterelles 13500 MARTIGUES, représenté(e) par Nans KARAYANNIDIS dûment habilité(e)
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, paddingBottom: 5}}>
          Le numéro individuel TVA de l’éditeur est : FR57925383507.
        </Typography>
        <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          Hébergement
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, paddingBottom: 5}}>
            {"Le Site est hébergé par la société AWS - Région West EU 2, situé AWS Data Center, Londres, (contact par leur site "}
            <a href="https://aws.amazon.com/fr/contact-us/" target="_blank"
               rel="noreferrer noopener">
                https://aws.amazon.com/fr/contact-us/
            </a> ).
        </Typography>
        <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          Directeur de publication
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2, paddingBottom: 5}}>
          Le Directeur de la publication du Site est Nans KARAYANNIDIS .
        </Typography>
        <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          Nous contacter
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          Par téléphone : <a href="tel:+33613588030">
            +33613588030
        </a>
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          Par email : <a href="mailto:contact@moncommercantdeproximite.fr">
            contact@moncommercantdeproximite.fr
        </a>
        </Typography>
        <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
          Par courrier : 67 allée des tourterelles 13500 MARTIGUES
      </Typography>
    </Box>
  );
};

export default MentionsLegales;
