import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import logo from '../logo/logo_navbar_1.png'
import Link from "@mui/material/Link";

const sections = [
    {title: "Accueil", anchor: "/#accueil"},
    {title: "Services", anchor: "/#services"},
    {title: "Notre ADN", anchor: "/#valeurs"},
    // { title: "Chiffre", anchor: "#chiffre" }
];

function Navbar() {
    const appBarStyle = {
        backgroundColor: "#FFFFFF", //"#FCFAF5"
        width: "95%",
        height: "14%",
        paddingLeft: "2%",
        paddingRight: "2%",
        position: "fixed",
        left: "2.5%",
        right: "2.5%",
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        alignItems: "center",
        justifyContent: "center",
        display: {xs: "none", md: "flex"},
        maxWidth: "xl",
        margin: "auto"
    };

    return (
        <AppBar sx={appBarStyle}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box>
                        <Link
                            href="/"
                        >
                        <img
                            src={logo}
                            alt="Logo"
                            width={200}
                        />
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            mx: 6,
                            flexGrow: 1,
                            display: {xs: "none", md: "flex"},
                            justifyContent: "flex-end"
                        }}
                    >
                        {sections.map((section) => (
                            <Button
                                key={section.title}
                                href={section.anchor}
                                sx={{
                                    mx: 2,
                                    my: 2,
                                    color: "#3FB498",
                                    fontWeight: "bold",
                                    fontStyle: "Outfit",
                                    display: "block"
                                }}
                            >
                                {section.title}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;
