import "../style/subnews.css"
import * as React from "react"

export default function SubNewsletter() {
    return (
        <div className="subnews">
            <iframe height={1150} width={800} title={"Form to subscribe"}
                    src="https://1e0d7774.sibforms.com/serve/MUIFAGWK5G6hfTrBuBBQF4PoQjsW9a8jjF7U_MKAs7VdK_krSoaA6Q36QkhsaBOEjxn4XrgzJb-hShNzU1BJcT8fFt2QTK9ZxXmyOMtjOCnkIvMmjMPkg2Wnto32WH9rzXLEaAl9ypWRM2G_D903fVevaP6MPQecIwJMQ_Lceh62nMleun2tXAg7PourU1XeEMOCMhYTMnbxqooi"
                    allowFullScreen
                    style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%"}}></iframe>
        </div>
    )
}