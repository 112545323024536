import * as React from "react";
import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import explor from "../pictos/gestion_stock.png";
import offres from "../pictos/reduction.png";
import contrib from "../pictos/localisation.png";


export default function NosServices() {
    return (
        <Box
            component="section"
            // sx={{
            //     padding: "1%",
            //     p: 2,
            //     height: "auto",
            //     backgroundColor: "#01796F",
            //     maxWidth: "xl",
            //     margin: "auto"
            //     //cursor: 'none'
            //     //backgroundColor:"#FCFAF5"
            //     //backgroundImage: `url(${background})`,
            //     //backgroundRepeat: "repeat"
            // }}
            sx={{
                padding: "1%",
                p: 2,
                height: "auto",
                backgroundColor: "#01796F", // Remettre l'ancienne couleur de fond
                maxWidth: "xl",
                margin: "auto",
                position: "relative", // Activer le positionnement absolu pour les pseudo-éléments
                overflow: "hidden", // Empêcher les débordements
                "::before, ::after": {
                    content: '""',
                    position: "absolute",
                    display:{xs: "none", md: "block", sm: "block"}, // cacher les demi-cercles pour les petits ecrans  
                    width: "200PX", // Ajuster la taille selon les besoins
                    height: "200px", // Ajuster la taille selon les besoins
                    backgroundColor: "#01796F", // Couleur des demi-cercles
                    borderRadius: "50%",
                    zIndex: 0,
                    border : "8px solid white"
                },
                "::before": {
                    top: "-50px", // Ajuster la position vers le haut
                    left: "-15px", // Ajuster la position vers l'extérieur
                    transform: "translate(-50%, 30%)"
                },
                "::after": {
                    bottom: "-50px", // Ajuster la position vers le bas
                    right: "-35px", // Ajuster la position vers l'extérieur
                    transform: "translate(50%, -30%)"
                }
            }}
            id="services"
        >
            <Grid item sx={{marginTop: '80px'}}>
                <Typography
                    variant="h4"
                    sx={{
                        color: "#FFFFFF",
                        fontFamily: "PinkSunset-Regular, sans-serif",
                        textAlign: "center",
                        fontWeight: "regular"
                    }}
                >
                    Découvrir MCP
                </Typography>
            </Grid>
            <Grid
                container
                spacing={2}
                sx={{
                    color: "#FFFFFF",
                    alignItems: "center",
                    marginTop: 3,
                    marginBottom: {xs: "30px", sm: '120px'},
                    justifyContent: {xs: '', sm: "space-around"}
                }}

            >
                <Grid container spacing={2} sx={{margin: 1, marginTop: 3, justifyContent: "space-around"}}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={explor} width={"20%"} alt={""}/>
                            <br/>
                            Exploration facile et intuitive
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Parcours une variété de commerces locaux, découvre de nouveaux produits et trouve facilement
                            ce que tu recherches.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={offres} width={"20%"} alt={""}/>
                            <br/>
                            Offres et cashprizes
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            Paye avec l’application sécurisée MCP, découvre les offres de tes commerçants
                            préférés, gagne du cashprize et dépense le chez les commerces partenaires !
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"
                                    sx={{textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif"}}>
                            <img src={contrib} style={{width: "13.3%"}} alt={""}/>
                            <br/>
                            Contribue à l’économie locale
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "center", fontFamily: "Outfit, sans-serif"}}>
                            En consommant et en partageant tes avis avec une communauté de consommateurs engagés !
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
}
// import * as React from "react";
// import Box from "@mui/material/Box";
// import { Grid, Typography } from "@mui/material";
// import explor from "../pictos/gestion_stock.png";
// import offres from "../pictos/reduction.png";
// import contrib from "../pictos/localisation.png";

// export default function NosServices() {
//     return (
//         <Box
//             component="section"
//             sx={{
//                 padding: "1%",
//                 p: 2,
//                 height: "auto",
//                 backgroundColor: "#01796F", // Remettre l'ancienne couleur de fond
//                 maxWidth: "xl",
//                 margin: "auto",
//                 position: "relative", // Activer le positionnement absolu pour les pseudo-éléments
//                 overflow: "hidden", // Empêcher les débordements
//                 "::before, ::after": {
//                     content: '""',
//                     position: "absolute",
//                     width: "200px", // Ajuster la taille selon les besoins
//                     height: "200px", // Ajuster la taille selon les besoins
//                     backgroundColor: "#2d8376", // Couleur des demi-cercles
//                     borderRadius: "50%",
//                     zIndex: 0 // S'assurer que les pseudo-éléments sont derrière le contenu
//                 },
//                 "::before": {
//                     top: "-50px", // Ajuster la position vers le haut
//                     left: "-50px", // Ajuster la position vers l'extérieur
//                     transform: "translate(-50%, -50%)"
//                 },
//                 "::after": {
//                     bottom: "-50px", // Ajuster la position vers le bas
//                     right: "-50px", // Ajuster la position vers l'extérieur
//                     transform: "translate(50%, 50%)"
//                 }
//             }}
//             id="services"
//         >
//             <Grid item sx={{ marginTop: '80px' }}>
//                 <Typography
//                     variant="h4"
//                     sx={{
//                         color: "#FFFFFF",
//                         fontFamily: "PinkSunset-Regular, sans-serif",
//                         textAlign: "center",
//                         fontWeight: "regular",
//                         position: "relative",
//                         zIndex: 1 // S'assurer que le texte est au-dessus de l'arrière-plan
//                     }}
//                 >
//                     Découvrir MCP
//                 </Typography>
//             </Grid>
//             <Grid
//                 container
//                 spacing={2}
//                 sx={{
//                     color: "#206657",
//                     alignItems: "center",
//                     marginTop: 3,
//                     marginBottom: { xs: "30px", sm: '120px' },
//                     justifyContent: { xs: '', sm: "space-around" },
//                     position: "relative",
//                     zIndex: 1 // S'assurer que le contenu est au-dessus de l'arrière-plan
//                 }}
//             >
//                 <Grid container spacing={2} sx={{ margin: 1, marginTop: 3, justifyContent: "space-around" }}>
//                     <Grid item xs={12} sm={6} md={3}>
//                         <Typography variant="h6"
//                             sx={{ textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif" }}>
//                             <img src={explor} width={"20%"} alt={""} />
//                             <br />
//                             Exploration facile et intuitive
//                         </Typography>
//                         <Typography variant="body1" sx={{ textAlign: "center", fontFamily: "Outfit, sans-serif" }}>
//                             Parcours une variété de commerces locaux, découvre de nouveaux produits et trouve facilement
//                             ce que tu recherches.
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={6} md={3}>
//                         <Typography variant="h6"
//                             sx={{ textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif" }}>
//                             <img src={offres} width={"20%"} alt={""} />
//                             <br />
//                             Offres et cashprizes
//                         </Typography>
//                         <Typography variant="body1" sx={{ textAlign: "center", fontFamily: "Outfit, sans-serif" }}>
//                             Paye avec l’application sécurisée MCP, découvre les offres de tes commerçants
//                             préférés, gagne du cashprize et dépense le chez les commerces partenaires !
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={6} md={3}>
//                         <Typography variant="h6"
//                             sx={{ textAlign: "center", fontWeight: "bold", fontFamily: "Outfit, sans-serif" }}>
//                             <img src={contrib} style={{ width: "13.3%" }} alt={""} />
//                             <br />
//                             Contribue à l’économie locale
//                         </Typography>
//                         <Typography variant="body1" sx={{ textAlign: "center", fontFamily: "Outfit, sans-serif" }}>
//                             En consommant et en partageant tes avis avec une communauté de consommateurs engagés !
//                         </Typography>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </Box>
//     );
// }
