import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ConditionGenerales = () => {
    return (
        <Box className="subnews" sx={{
            maxWidth: "xl",
            margin: "auto"
        }}>
            <Typography variant="h4" sx={{fontFamily: "Outfit, sans-serif", margin: 2, textAlign:"center", paddingBottom: 8}}>
                CONDITIONS GÉNÉRALES D’UTILISATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La société MCP – Mon commerçant de proximité, ayant pour nom commercial MCP – Mon commerçant de
                proximité, est une société par actions simplifiée, au capital de 2 000 euros dont le siège social est
                situé 67 allée des tourterelles 13500 MARTIGUES et inscrite au RCS de Aix-en-Provence sous le numéro 925
                383 507.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité développe et gère une application mobile de mise en relation et de
                paiement entre particuliers et professionnels avec une dimension fidélisante, ludique et simpliste.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les présentes conditions générales d’utilisation (ci-après dénommées « CGU ») ont pour objet de
                déterminer les règles d’utilisation de l’application mobile « MCP – Mon commerçant de proximité »
                (ci-après dénommée l’« Application »).
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                En installant l’Application sur votre terminal et en accédant à l’Application en tant qu’Utilisateur
                vous acceptez sans condition ni réserve l’intégralité des présentes CGU.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité se réserve la faculté de modifier tout ou partie des présentes CGU à
                tout moment afin de les adapter à l’évolution des services ainsi qu’aux évolutions techniques, légales
                ou jurisprudentielles. Les CGU applicables sont celles en vigueur à la date de la connexion et
                d’utilisation de l’Application par l’Utilisateur. MCP – Mon commerçant de proximité invite donc
                l’Utilisateur à se référer régulièrement à la dernière version des CGU disponible dans l’Application et
                sur le Site de MCP – Mon commerçant de proximité.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 1 : DÉFINITION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les termes débutant par une majuscule au sein des présentes CGU, qu’ils soient utilisés indifféremment
                au singulier ou au pluriel, auront la signification qui leur est donnée ci-après :
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Application” désigne l’application logicielle “ MCP – Mon commerçant de proximité ” éditée et fournie
                par MCP – Mon commerçant de proximité donnant accès aux Services et qui est disponible gratuitement dans
                l' “App Store” d’Apple et le “ Google Play Store ” de Google pour être téléchargée par l’Utilisateur sur
                son terminal Apple iOS et Android. L’Application comprend également les Contenus, les logiciels, les
                programmes, les outils (de programmation, de navigation, …), les bases de données, les systèmes
                d’exploitation, la documentation et tous autres éléments et services qui la composent ainsi que les
                mises à jour et les nouvelles versions qui peuvent y être apportées par MCP – Mon commerçant de
                proximité.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Cashprize” désigne la somme obtenue à la suite d’un tirage au sort et pouvant être utilisée chez des
                Partenaires.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Cagnotte” désigne l’espace sur l’Application où est stocké le Cashprize et/ou dépôts de l’Utilisateur.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Compte” désigne l’espace personnel de chaque Utilisateur auquel ce dernier accède grâce à ses
                Identifiants. En activant son Compte, un Utilisateur pourra profiter des Services.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Contenu” désigne, sans que cette liste soit limitative, la structure de l’Application, le contenu
                éditorial, les dessins, les illustrations, les images, les photographies, les chartes graphiques, les
                marques, les logos, les sigles, les dénominations sociales, les œuvres audiovisuelles, les œuvres
                multimédias, les contenus visuels, les contenus audio et sonores, ainsi que tout autre contenu présent
                au sein de l’Application et/ou tout autre élément composant l’Application.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Contenu Utilisateur” désigne le Contenu soumis par l’Utilisateur ou transmis à, par l’intermédiaire de
                ou en relation avec l’Application, tel que les évaluations, avis, photos, vidéos, messages et
                informations.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Identifiant” désigne l’adresse électronique personnelle et valide permettant à l’Utilisateur son
                identification sur l’Application.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Page” désigne le contenu créé par un Utilisateur indiquant les caractéristiques d’un commerce tels que
                son nom commercial, la catégorie de biens ou services proposés, son adresse, son numéro de téléphone,
                son site internet, son adresse électronique, ses horaires d’ouverture, sa photo de profil et des photos
                d’ambiance. Le Partenaire exploitant ce fonds de commerce peut s’approprier cette Page.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Partenaires” désigne la société tierce ayant adhéré au Conditions Générales de Services de MCP – Mon
                commerçant de proximité.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Services” désigne les différentes fonctionnalités et services proposés par l’Application à savoir la
                possibilité pour les Utilisateurs d’entrer en relation avec des Partenaires afin d’acheter leurs
                produits ou service, de créer et/ou de participer à des événements avec d’autres Utilisateurs ainsi que
                de discuter avec leurs contacts sur la messagerie intégrée à l’Application.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                {'“Site” désigne le site web officiel de MCP – Mon commerçant de proximité dont l’adresse est la suivante '}
                <a href="https://moncommercantdeproximite.fr" target="_blank"
                   rel="noreferrer noopener">
                    https://moncommercantdeproximite.fr
                </a>.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                “Utilisateur” ou “Vous” désigne une personne physique majeure ayant téléchargé l’Application pour ses
                besoins propres, dans le cadre d’un usage strictement personnel et non commercial.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 2 : OBJET DE L’APPLICATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Application a pour objet de permettre aux Utilisateurs d’accéder aux Services à travers différentes
                fonctionnalités.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La fonctionnalité “Cashprize” permet aux Utilisateurs de bénéficier participation aux tirages au sort.
                Les paiements in-app donnent droit à des participations (10€ par participation pour les tirages
                classiques et 20€ pour les tirages spéciaux). Le cashprize est ensuite ajouté à la cagnotte de
                l’Utilisateur et peut être utilisé chez nos Partenaires. La liste des Partenaires est disponible sur
                l’Application. Elle est mise à jour en temps réel.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La fonctionnalité “Mise en avant” permet aux Commerçants de participer à des tirages au sort où ils
                pourront gagner des mises en avant temporaires gratuites sur l’application. La participation à ces
                tirages au sort est comptabilisée en fonction de leurs ventes in-app (10€ par participation pour les
                tirages classiques et 20€ pour les tirages spéciaux).
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La fonctionnalité “Commerces référencés” permet aux Utilisateurs de référencer des Pages présentant des
                commerces de proximité (adresse, numéro de téléphone, type de commerce, photo etc) que les commerçants
                pourront s’approprier dans les conditions prévues aux conditions générales de services de MCP – Mon
                commerçant de proximité.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les fonctionnalités “Recherche” et “Map” permet aux Utilisateurs d’obtenir des informations sur des
                commerçants situés à proximité d’eux afin de trouver aisément les produits, services et/ou promotions
                qu’ils proposent. Elle permet aussi aux Utilisateurs d’acheter ces produits et/ou services proposés par
                ces commerçants.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La fonctionnalité “Promotions” permet aux Commerçants d’organiser des promotions sur des produits de
                leur stock.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La fonctionnalité “Paiement” permet aux Commerçants d’accepter des paiements en carte bancaire
                directement depuis leur mobile en utilisant l’application, et aux Utilisateurs de payer en carte
                bancaire ou avec leur téléphone. MCP – Mon commerçant de proximité prélève 4,5% du montant payé par
                l’Utilisateur. Ce montant est prélevé avant transfert au commerçant. Ainsi, si un Utilisateur fait un
                achat de 100€, le commerçant recevra 95,50€.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 3 : DISPONIBILITÉ DE L’APPLICATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Application est accessible en France métropolitaine.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Application est accessible 24 heures sur 24, 7 jours sur 7 sauf cas de force majeure et éventuelles
                pannes et interventions de maintenance nécessaires au bon fonctionnement de l’Application.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Application peut être mise à jour ponctuellement pour ajouter de nouvelles fonctionnalités.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les interventions de maintenance et/ou mises à jour pourront être effectuées sans que les Utilisateurs
                aient été préalablement avertis.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 4 : CONDITIONS D’ACCÈS
            </Typography>

            <Typography variant="h6" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                4.1 : Téléchargement
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les équipements et moyens matériels permettant l’accès à l’Application sont à la charge exclusive de
                l’Utilisateur.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Pour accéder et utiliser l’Application, l’Utilisateur doit disposer :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● d’un téléphone compatible ou un terminal mobile compatible ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● d’un accès au réseau internet ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● d’un compte client dans un store virtuel « Google Play Store » et/ou « Apple App Store ».
                </Typography>
            </Typography>

            <Typography variant="h6" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                4.2 : Création du compte
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Afin d’avoir accès à l’Application, l’Utilisateur devra créer un Compte. L’Utilisateur garantit à MCP –
                Mon commerçant de proximité que les informations fournies lors de son inscription sont exactes et
                complètes. Il s’engage à les mettre à jour dès que nécessaire. Il appartient à l’Utilisateur d’assurer
                la confidentialité du mot de passe nécessaire à son inscription. Toute connexion au compte sera présumée
                avoir été faite par l’Utilisateur lui-même. A ce titre, l’Utilisateur sera responsable de toute action
                effectuée par l’intermédiaire de son Compte.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur est en droit de s'inscrire sur la liste d'opposition au démarchage téléphonique.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 5 : CONDITIONS FINANCIÈRES
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Application est proposée gratuitement aux Utilisateurs. Les coûts suivants restent à la charge
                exclusive de l’Utilisateur :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Coût d’abonnement auprès de l’opérateur de téléphonie mobile ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Coût de connexion et d’accès au réseau Internet ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Surcoût éventuel facturé pour le chargement des données.
                </Typography>
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 6 : LICENCE D’UTILISATION DE L’APPLICATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité concède à l’Utilisateur un droit personnel d’utilisation de
                l’Application et des Services, non exclusif, révocable, non cessible, non transférable et gratuit,
                uniquement pour ses besoins propres, à l’exclusion de toute autre finalité.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les présentes CGU ne confèrent aucun droit à l’Utilisateur sur le Contenu. Il est interdit à
                l’Utilisateur de créer des œuvres dérivées de l’Application, d’utiliser des logiciels ou des procédés
                destinés à copier le Contenu sans l’autorisation préalable de MCP – Mon commerçant de proximité,
                d’exporter l’Application, fusionner tout ou partie de l’Application avec d’autres programmes
                informatiques, de reproduire de façon permanente ou provisoire tout ou partie de l’Application, par tout
                moyen et sous toute forme, d’extraire ou de réutiliser, y compris à des fins privées, sans autorisation
                préalable écrite de MCP – Mon commerçant de proximité, tout ou partie du contenu des bases de données et
                archives constituées par l'Application, de mettre en place des systèmes susceptibles de pirater
                l’Application et/ou le Contenu en tout ou partie, ou de nature à violer les présentes CGU.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur s’engage à s’abstenir, directement, indirectement ou par des moyens automatisés ou autres,
                de copier, adapter, modifier, distribuer, concéder en licence, concéder en sous-licence, transférer les
                Services, en créer des œuvres dérivées ou les exploiter d'une quelconque autre manière non autorisée ou
                inacceptable, ou qui entrave ou porte préjudice, à MCP – Mon commerçant de proximité, aux Services, aux
                systèmes, Utilisateurs ou autres. L’Utilisateur ne doit en aucun cas, directement ou par des moyens
                automatisés : (a) réaliser de l'ingénierie inverse, modifier, décompiler les Services, en créer des
                œuvres dérivées ou en extraire le code ; (b) envoyer, stocker ou transmettre des virus ou tout autre
                code informatique dangereux par le biais des Services ou sur ces derniers ; (c) obtenir ou tenter
                d'obtenir un accès non autorisé aux Services ou systèmes ; (d) entraver ou perturber la sécurité, la
                confidentialité, l'intégrité, la disponibilité ou la prestation des Services ; (e) créer des comptes sur
                les Services par des moyens automatisés ou non autorisés ; (f) collecter les coordonnées des
                Utilisateurs ou des informations à leur sujet de manière non autorisée ou illégale ; (g) vendre,
                revendre, louer ou facturer les Services ou des données obtenues auprès de MCP – Mon commerçant de
                proximité ou des Services, d'une manière non autorisée ; (h) distribuer les Services ou les mettre à
                disposition sur un réseau permettant de les utiliser sur plusieurs appareils à la fois, sauf
                autorisation de MCP – Mon commerçant de proximité et par le biais d'outils expressément fournis via les
                Services ; (i) créer des logiciels ou des API qui fonctionnent essentiellement de la même façon que les
                Services et les proposer à des tiers d'une manière non autorisée ; ou (j) utiliser les moyens de
                signalement à mauvais escient, en effectuant des signalements ou des réclamations frauduleux, infondés
                et/ou abusifs.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Il est interdit à l’Utilisateur d’utiliser les Services d'une manière qui viole, détourne ou enfreint
                les droits de MCP – Mon commerçant de proximité, de ses Utilisateurs ou d'autres personnes, y compris
                les droits à la vie privée, à l'image, de propriété intellectuelle ou autres droits exclusifs. Il est
                interdit à l’Utilisateur d’utiliser les Services de manière illégale, obscène, diffamatoire, menaçante,
                intimidante, haineuse, racialement offensante. Est assimilée à du harcèlement ou incite ou encourage un
                comportement illégal ou déplacé, y compris la promotion de crimes violents, la mise en danger ou
                l'exploitation d'enfants ou d'autres individus, ou la coordination de violence. Il est également
                interdit à l’Utilisateur de publier par le biais des Services des fausses déclarations ainsi que
                d’usurper l'identité de quelqu'un.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Enfin, il est interdit à l’Utilisateur d'envoyer par le biais des Services des communications illégales
                ou inacceptables tels que des messages envoyés en masse, des messages automatiques et autres pratiques
                similaires.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                En cas de non-respect de ces stipulations, l’Utilisateur est passible de poursuites et de dommages et
                intérêts.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 7: FONCTIONNEMENT DES SERVICES
            </Typography>

            <Typography variant="h6" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                7.1 Paiement
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Afin de pouvoir bénéficier du service de paiement, l’Utilisateur doit se rendre chez un Partenaire et
                payer ses achats en scannant un QR Code ou en utilisant le NFC (paiement de mobile à mobile si les deux
                sont équipés de la technologie, ou de carte bancaire sans contact à mobile).
            </Typography>

            <Typography variant="h6" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                7.2 Tirages au sort
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Chaque Utilisateur pourra participer aux tirages au sort ayant lieu sur l’application. Les commerçants
                auront une chance de gagner des mises en avant pour leurs commerces tandis que les consommateurs auront
                la possibilité de gagner du cashprize.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Le Cashprize ne peut être utilisé que chez les Partenaires. L’Utilisateur ne pourra donc pas exiger leur
                paiement par virement bancaire ou espèce ou encore leur remboursement auprès de MCP – Mon commerçant de
                proximité et/ou d’un Partenaire.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Le Cashprize est ajouté au Compte de l’Utilisateur immédiatement après le tirage au sort effectué.
                Celui-ci peut ensuite l’utiliser pour payer d’autres achats auprès des différents Partenaires.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Dès lors qu’un tirage au sort prend fin, les participations pour le suivant débutent.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                En cas de suppression du Compte de l’Utilisateur, la cagnotte sera supprimée sans qu’il soit possible à
                l’Utilisateur de se la faire rembourser par MCP – Mon commerçant de proximité.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 8 : DROITS, OBLIGATIONS, RESPONSABILITÉ DE MCP - MON COMMERÇANT DE PROXIMITÉ
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité fera ses meilleurs efforts pour assurer le bon fonctionnement de
                l'Application, il s'engage à sécuriser l'accès, la consultation et l'utilisation de l'Application et du
                Site.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité s'engage à procéder ou faire procéder aux interventions de maintenance
                nécessaires pour rétablir le bon fonctionnement de l'Application ou de son Site dans les plus brefs
                délais.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité s’assure de la publication de l’Application sur les stores et que
                l’Application soit compatible avec la plupart des téléphones portables du marché. Du fait du grand
                nombre de modèles, de systèmes d’exploitation divers (à jour ou non), des surcoûts éventuels des
                opérateurs, et des différents logiciels installés par l’Utilisateur, MCP – Mon commerçant de proximité
                ne peut garantir la parfaite compatibilité de l’Application avec tous les téléphones portables et ne
                sera donc pas responsable en cas de mauvais fonctionnement.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 9 : LIMITATION ET EXCLUSION DE RESPONSABILITÉ
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La responsabilité de MCP – Mon commerçant de proximité ne saurait être engagée pour les risques acceptés
                par l’Utilisateur.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité ne peut être tenu pour responsable :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● En cas de perte, de retard ou d’erreur de transmission de données indépendants de sa volonté ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Des dommages de toute nature, directs ou indirects, résultant de l’incapacité à utiliser
                    l’Application et notamment toute perte financière ou commerciale et/ou perte de données ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Des dommages de toute nature, directs ou indirects, résultant du contenu et/ou de l’utilisation ou
                    de l’incapacité à utiliser le Site ou auxquels les Utilisateurs pourraient avoir accès via
                    l’Application ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Des dommages de toute nature, causés aux Utilisateurs, à leurs terminaux, à leurs équipements
                    informatiques et téléphoniques et aux données qui y sont stockées ni des conséquences pouvant en
                    découler sur leur activités personnelles, professionnelle ou commerciale ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Si des données ne lui parvenaient pas, pour quelque raison que ce soit, ou si les données qu’il
                    reçoit étaient illisibles ou impossibles à traiter ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Au cas où l’Utilisateur ne parvenait pas à accéder ou à utiliser le Site, l’Application et/ou les
                    Services pour quelque raison que ce soit, notamment en cas d’opérations de maintenance, de mise à
                    jour ou d’améliorations techniques ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Si, pour quelque raison que ce soit, la connexion devait être interrompue ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● En cas de mauvaise utilisation du terminal et/ou d'incident lié à l'utilisation du terminal lors
                    de l’utilisation de l’Application.
                </Typography>

            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La responsabilité de MCP – Mon commerçant de proximité ne peut être engagée qu’en cas de faute et est
                limitée aux préjudices matériels directs. En tout état de cause, la responsabilité de MCP – Mon
                commerçant de proximité est limitée à 2.000 euros, toutes causes de préjudices confondues.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité intervient en tant qu’intermédiaire entre le l’Utilisateur et le
                Partenaire. A ce titre, sa responsabilité ne peut être engagée par l’Utilisateur dans le cadre des
                relations entre Utilisateur et Partenaire.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur reconnaît que les limitations de responsabilité ci-dessus ne privent pas d’effet
                l’obligation essentielle de chacune des Parties et qu’elles sont en cohérence avec les enjeux du contrat
                les liant.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 10 : LIMITES GÉOGRAPHIQUES ET GÉOLOCALISATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’utilisation de l’application MCP – Mon commerçant de proximité est strictement limitée à la France.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Afin de permettre une utilisation optimale de l’Application, l'utilisation de la fonctionnalité de
                géolocalisation de l'Application nécessite le consentement préalable express de l'Utilisateur.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 11 : PROTECTION DES DONNÉES PERSONNELLES
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité s’engage à respecter la législation relative à la protection des
                données personnelles et notamment la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux
                fichiers et aux libertés ainsi que le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
                avril 2016, relatif à la protection des personnes physiques à l'égard du traitement des données à
                caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE
                (Règlement Général sur la Protection des Données).
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur s’engage à accepter la Politique de confidentialité de MCP – Mon commerçant de proximité.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L'Utilisateur est informé du fait que l’Application utilise des traceurs ou « cookies » conçus pour être
                utilisés par MCP – Mon commerçant de proximité.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 12 : LA SÉCURITÉ DE L’APPLICATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Application est fournie « en l’état » sans garantie quelle qu’elle soit. L’accès et l’utilisation de
                l'Application se font aux risques et périls de l'Utilisateur.
            </Typography>
            <Typography variant="h6" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                12.1 : RISQUES POUR L'UTILISATEUR
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à protéger ses propres
                données et/ou logiciels stockés sur son et/ou ses matériels (téléphonique, informatique) contre toute
                atteinte, notamment contre tout dysfonctionnement, virus et/ou piratage.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                En utilisant l’Application, l’Utilisateur assume toutes les conséquences que pourraient engendrer
                l’Application.
            </Typography>
            <Typography variant="h6" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                12.2 : RISQUE SUR LE « RÉSEAU INTERNET »
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les performances techniques de l’Internet mobile requièrent un temps de traitement nécessaire pour
                répondre, consulter, interroger ou transférer les Services.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur déclare connaître et accepter les limites et les problématiques du réseau Internet et pour
                lesquelles la responsabilité de MCP – Mon commerçant de proximité ne saurait être engagée et notamment :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Les caractéristiques et les limites du réseau Internet et, notamment les caractéristiques
                    fonctionnelles et des performances techniques du réseau Internet ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Les problèmes liés à la connexion et/ou d’accès au réseau Internet et/ou aux sites web ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Les problèmes liés à la disponibilité et à l’encombrement des réseaux ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Les problèmes liés à la défaillance ou à la saturation des réseaux ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Les problèmes liés au temps de transit, à l’accès aux informations mises en ligne, aux temps de
                    réponse pour afficher, consulter, interroger ou autrement transférer des données ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Les risques d’interruption ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● L’absence de protection de certaines données contre des détournements éventuels ou piratage ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● Les risques de contamination par d’éventuels virus circulant sur lesdits réseaux.
                </Typography>
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 13 : PROPRIÉTÉ INTELLECTUELLE - DROIT À L'IMAGE
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité est le propriétaire exclusif de tous les droits de propriété
                intellectuelle afférents à l’Application et son Contenu. Le téléchargement de l’Application ne confère
                aucun droit à l’Utilisateur sur les droits de propriété intellectuelle de MCP – Mon commerçant de
                proximité.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Tous les droits de reproduction et de représentation sont protégés, y compris pour les documents
                téléchargeables. L’ensemble des textes, graphismes, icônes, photographies, plans, logos, vidéos, sons,
                marques, et plus généralement l’ensemble des éléments composant l’Application ne peuvent faire l’objet
                d’une quelconque représentation, reproduction, exploitation ou extraction, intégrale ou partielle, sur
                quelque support que ce soit, sans l’autorisation expresse et préalable de MCP – Mon commerçant de
                proximité.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur s’engage expressément à ce que l’utilisation de l’Application ne porte en aucun cas
                atteinte aux droits de MCP – Mon commerçant de proximité, et notamment à ce que cette utilisation ne
                constitue pas un acte de contrefaçon, de concurrence déloyale ou parasitaire.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur déclare être propriétaire de son Contenu Utilisateur tel qu’il est décrit dans les
                présentes, ou qu’il est en possession des autorisations nécessaires pour l’utiliser et pour permettre
                son utilisation. MCP – Mon commerçant de proximité pourra utiliser le Contenu Utilisateur de différentes
                façons, y compris en le publiant, en modifiant le format, l’incorporant à des publicités ou autres
                documents, créant des œuvres dérivées de celui-ci, le mettant en valeur, le distribuant, et en
                permettant à d’autres d’agir de même sur leurs sites Web et plates-formes médiatiques. Ainsi, par les
                présentes, l’Utilisateur accorde de façon irrévocable les droits d’utilisation de son Contenu
                Utilisateur à toutes fins, ces droits étant internationaux, perpétuels, non exclusifs, exempts de
                redevance, pouvant faire l’objet d’une sous-licence, cessibles et transférables. L’Utilisateur accorde
                également par les présentes aux autres Utilisateurs ainsi qu’aux Partenaires le droit irrévocable
                d’accéder à son Contenu Utilisateur dans le cadre de leur utilisation de l’Application. L’Utilisateur
                renonce irrévocablement à tout recours et revendication à l’encontre de MCP – Mon commerçant de
                proximité, de ses Utilisateurs et des Partenaires relatives aux droits moraux ou patrimoniaux en ce qui
                concerne son Contenu Utilisateur.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                L’Utilisateur autorise MCP – Mon commerçant de proximité à reproduire et diffuser, à titre gratuit en
                France et à l’étranger, les photographies et/ou les vidéos le représentant qu’il aurait volontairement
                publiées et diffusées par le biais de l’Application.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                En conséquence, l’Utilisateur autorise MCP – Mon commerçant de proximité à exploiter les images le
                représentant aux fins de permettre la communication et la promotion de MCP – Mon commerçant de
                proximité, de ses services et/ou de ses clients, sous toutes formes et tous supports, connus et inconnus
                à ce jour, intégralement ou par extraits, et notamment : presse, site internet, réseaux sociaux,
                affichage, plaquette publicitaire, invitations…
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                La présente autorisation est délivrée à titre exclusif et pour une durée de 10 ans.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 14 : RÉSILIATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité peut décider la fin de la mise à disposition de l’Application à tout
                moment sous réserve de respecter un préavis de 8 jours. La notification de la fin de la mise à
                disposition se fera par e-mail ou par affichage d’un message sur l’Application.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité n’a pas à motiver sa décision de mettre fin à la mise à disposition de
                l’Application et cette décision ne peut donner lieu à une indemnisation ni à un remboursement.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité peut résilier la licence d’utilisation de l’Application de tout
                Utilisateur qui n’en respecterait pas les termes.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 15 : INDIVISION DES CGU DE L’APPLICATION
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Si tout ou partie des présentes CGU venait à être jugée illégale par une juridiction, la stipulation
                invalidée serait alors supprimée sans incidence sur le reste des CGU. Les autres stipulations des
                présentes CGU resteraient valides et applicables.
            </Typography>

            <Typography variant="h5" sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Article 16 : RECOURS, JURIDICTION COMPÉTENTE ET DROIT APPLICABLE EN CAS DE LITIGE
            </Typography>

            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Les présentes CGU sont soumises au droit français.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Tout litige, différend ou réclamation, soulevé par un Utilisateur, devra être notifié au service
                juridique de MCP – Mon commerçant de proximité par lettre recommandée avec accusé de réception, avant
                toute saisine du juge, à l’adresse indiquée ci-dessous :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    MCP – Mon commerçant de proximité
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    67 allée des tourterelles
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    13500 MARTIGUES
                </Typography>
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                MCP – Mon commerçant de proximité et l’Utilisateur conviennent de mettre en œuvre leurs meilleurs
                efforts pour parvenir à une solution amiable.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Par ailleurs, conformément aux dispositions des articles L612-1 et suivants du Code de la consommation,
                l’Utilisateur a la faculté de recourir gratuitement (sauf éventuels frais d’avocat ou d’expertise) à un
                médiateur de la consommation.
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                {'Pour ce faire, l’Utilisateur peut contacter le(s) médiateur(s) de la consommation à l’adresse suivante : '}
                <a href="https://www.economie.gouv.fr/mediation-conso/mediateurs-references" target="_blank"
                   rel="noreferrer noopener">
                    https://www.economie.gouv.fr/mediation-conso/mediateurs-references </a>
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                {'En outre, l’Utilisateur a la possibilité de recourir à la plateforme européenne de Résolution des Litiges en Ligne (RLL), accessible à l’adresse suivante : '}
                <a href="https://ec.europa.eu/consumers/odr/" target="_blank"
                   rel="noreferrer noopener">
                    https://ec.europa.eu/consumers/odr/ </a>
            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Il est précisé que la demande de médiation de l’Utilisateur ne sera recevable que si :
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● l’Utilisateur a d’abord tenté de résoudre son litige directement auprès de MCP – Mon commerçant de
                    proximité par une réclamation écrite, selon les modalités décrites précédemment ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● la demande de l’Utilisateur n’est pas manifestement infondée ou abusive ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● la demande de l’Utilisateur est introduite dans un délai inférieur à un an à compter de sa
                    réclamation écrite auprès de MCP – Mon commerçant de proximité ;
                </Typography>
                <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                    ● le litige n’a pas été précédemment examiné par un autre médiateur ou par un tribunal.
                </Typography>

            </Typography>
            <Typography sx={{fontFamily: "Outfit, sans-serif", margin: 2}}>
                Si MCP – Mon commerçant de proximité et l’Utilisateur ne parvenaient pas à trouver une solution amiable,
                le litige serait soumis aux tribunaux d’Aix-En-Provence.
            </Typography>

        </Box>
    );
};

export default ConditionGenerales;
