import * as React from 'react';
import Introduction from './Introduction'
import NosServices from './NosServices'
import NotreADN from './NotreADN'
import Soutenir from './Soutenir'
import Map from './Map'

function Home() {

    return (
        <>
            <Introduction/>
            <Map/>
            <NosServices/>
            <NotreADN/>
            <Soutenir/>
        </>
    );
}

export default Home;
