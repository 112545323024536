// Layout.js
import React from 'react';

const Layout = ({children}) => {
    return (
        <>
            <div className="content">{children}</div>
        </>
    );
};

export default Layout;
