import Link from "@mui/material/Link";
import * as React from "react";
import {useState} from "react";
import "../style/navigation.css";
import logo from "../logo/logo_navbar_1.png";

const Navigation = ({items}) => {
    const renderItems = () => items.map((item, index) => (
        <li key={index}>
            {item.url
                ? <Link href={item.url} onClick={() => closeMenu()}>{item.name}</Link>
                : <span onClick={toggleSubMenu}>
                        {item.name}
                    <img src="../logo/picto1.png" alt="MCP logo"/>
                </span>
            }
            {item.children && renderChildren(item.children)}
        </li>
    ))

    const renderChildren = (children) => (
        <ul className="sub-menu">
            {children.map((child, index) => (
                <li key={index}>
                    <Link href={child.url} onClick={() => closeMenu(true)}>
                        {child.name}
                    </Link>
                </li>
            ))}
        </ul>
    )

    const toggleSubMenu = event => {
        event.currentTarget.classList.toggle('toggled')
    }
    const closeMenu = (closeSubMenu = false) => {
        setToggled(false)

        if (closeSubMenu && window.innerWidth > screenSizes.small) {
            setCloseSubMenu(true)
            setTimeout(() => setCloseSubMenu(false), 0)
        }
    }
    const [closeSubMenu, setCloseSubMenu] = useState(false)
    const [toggled, setToggled] = useState(false)

    const screenSizes = {
        small: 600
    }
    return (
        <nav className="custom-nav">
            <div className="custom-container">
                <Link href="/">
                    <img
                    src={logo}
                    alt="Logo"
                    width={200}
                />
                </Link>
                <div
                    className={toggled ? 'hamburger close' : 'hamburger'}
                    onClick={() => setToggled(!toggled)}
                >
                    <span className="meat"></span>
                    <span className="meat"></span>
                    <span className="meat"></span>
                    <span className="meat"></span>
                </div>
            </div>
            <ul
                className={[
                    'menu',
                    toggled && 'active',
                    closeSubMenu && 'closed'
                ].filter(Boolean).join(' ')}
            >
                {renderItems()}
            </ul>
        </nav>
    )
}

export default Navigation;
