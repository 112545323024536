import React from "react";
import "./App.css";
import {ThemeProvider} from "@emotion/react";
import {createTheme} from "@mui/material";
import Navbar from "./components/navbar";
import Footer from "./components/Footer";
import {BrowserRouter as Router} from 'react-router-dom';
import AppRoutes from "./routes";
import Layout from "./components/Layout";
import Navigation from "./components/mobilenav";

let theme = createTheme({});

function App() {
    const items = [
        {name: "Accueil", url: "/#accueil"},
        {name: "Services", url: "/#services"},
        {name: "Notre ADN", url: "/#valeurs"},
    ]
    theme = createTheme({
        palette: {
            custom: theme.palette.augmentColor({
                color: {main: "#01796F"},
                name: "custom"
            })
        }
    });
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Layout>
                    <Navbar/>
                    <Navigation items={items} sx={{display: {xs: "none", md: "flex"}}}/>
                    <AppRoutes/>
                    <Footer/>
                </Layout>
            </Router>
        </ThemeProvider>
    );
}

export default App;
